import React from 'react';
import apiFetch from '../../src/js/fetch';
import AdminCreateSegmentHandler from '../../src/js/segments/new-admin';
import ContentContainer from '../ContentContainer';
import editIcon from '../../images/icons/ic-edit.svg';
import Chart from 'chart.js/auto';

import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';

import BarLoader from 'react-spinners/BarLoader';

class AdminCreateSegment extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // form
      name: '',
      description: '',
      errors: {},
      segmentTypeId: '',

      loading: true,

      // selection
      selectionDistance: null,
      coordinates: [],

      // drawType
      drawType: 'direction-api',
    };

    this.mapHandler = null;

    this.handleSelection = this.handleSelection.bind(this);
  }

  componentDidMount() {
    this.mapHandler = new AdminCreateSegmentHandler({
      customSegmentCallback: this.handleSelection,
      drawType: this.state.drawType,
    });
  }

  componentWillUnmount() {
    // Remove the map
    console.log('unmounting');
    if (this.mapHandler) {
      this.mapHandler.unmount();
    }
  }

  handleSelection = (selection) => {
    console.log({ selection });

    // Update the selection data, and set new range of the slider
    this.setState({
      selectionDistance: selection?.distance,
      coordinates: selection?.coordinates,
      error: null,
    });

    if (selection?.coordinates) {
      const ctx = document.querySelector('.elevation-chart').getContext('2d');
      console.log({ ctx });

      if (this.chart) {
        this.chart.destroy();
      }
      const chartData = {
        labels: selection?.coordinates.map((c) => ''),
        datasets: [
          {
            label: 'Elevations',
            data: selection?.coordinates.map((c) => c.alt),
          },
        ],
      };
      console.log({ chartData });
      const config = {
        type: 'line',
        data: chartData,
      };
      console.log({ config });

      this.chart = new Chart(ctx, config);
    }
  };

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
      error: null,
    });
  };

  handleSubmit = async (event) => {
    event.preventDefault();

    const { name, description, segmentTypeId } = this.state;
    const selection = this.mapHandler && this.mapHandler.getRoutingSelection();

    if (!selection || !selection.startPoint || !selection.stopPoint || !name) {
      console.log('Missing data:', { name, selection });
      this.setState({
        loading: false,
        error:
          'You need to fill in all fields, and select a course by dragging the start and stop along your activity.',
      });
      return;
    }

    this.setState({ loading: true });

    console.log('Submit form');
    const data = {
      segment: {
        name: name,
        description: description,
        distance_in_meters: selection.distance,
        coordinates: selection.coordinates,
        start_lat: selection.startPoint.lat,
        start_lng: selection.startPoint.lng,
        stop_lat: selection.stopPoint.lat,
        stop_lng: selection.stopPoint.lng,
        segment_type_id: parseInt(segmentTypeId),
      },
    };
    console.log({ data });

    try {
      const created = await apiFetch('/api/segments/admin-create', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify(data),
      });
      console.log({ created });

      window.location.href = created.url; // "/segments/" + created.id;
    } catch (e) {
      console.log({ e });
      const err = await e.json();
      console.log({ err });
      this.setState({
        loading: false,
        error:
          err.error_message || 'Something went wrong. Please try again later.',
      });
    }
  };

  handleChangeDrawType = (event) => {
    this.mapHandler.unmount();

    this.setState({
      drawType: event.target.value,
    });

    this.mapHandler = new AdminCreateSegmentHandler({
      customSegmentCallback: this.handleSelection,
      drawType: event.target.value,
    });
  };

  render() {
    const {
      name,
      description,
      selectionDistance,
      coordinates,
      error,
      segmentTypeId,
    } = this.state;

    return (
      <section className='pt-8'>
        <form
          id='create-segment-form'
          className=''
          method='post'
          onSubmit={this.handleSubmit}
        >
          <div className='relative w-full lg:max-w-[53rem]'>
            <input
              type='text'
              name='name'
              id='name'
              autoFocus
              className={`big-heading mb-4 pr-6 align-top bg-transparent appearance-none ${
                error && !name ? 'border-b border-error' : ''
              }`}
              required={true}
              onChange={this.handleChange}
              value={name}
              placeholder='New segment'
            />
            <img
              src={editIcon}
              className='absolute right-0 my-auto top-0 bottom-0 mr-2 cursor-text pointer-events-none'
            />
          </div>

          <div className='mb-16pt'>
            <div className='w-full lg:max-w-[53rem] grid gap-4'>
              <ContentContainer>
                <h2 className='mini-label font-medium'>Segment type</h2>
                <select
                  name='segmentTypeId'
                  id='segmentTypeId'
                  className='w-full input'
                  required={true}
                  onChange={this.handleChange}
                  value={segmentTypeId}
                >
                  <option value=''>Select segment type</option>
                  {this.props.segment_types.map((type) => (
                    <option value={type.id}>{type.name}</option>
                  ))}
                </select>

                <br />

                <h2 className='mini-label font-medium'>How to draw segment?</h2>
                <div className='flex items-center'>
                  <input
                    type='radio'
                    name='drawType'
                    id='drawType-direction-api'
                    value='direction-api'
                    checked={this.state.drawType === 'direction-api'}
                    onChange={this.handleChangeDrawType}
                  />
                  <label htmlFor='drawType-direction-api' className='ml-2'>
                    Use Google Directions API
                  </label>

                  <input
                    type='radio'
                    name='drawType'
                    id='drawType-manual'
                    value='manual'
                    checked={this.state.drawType === 'manual'}
                    onChange={this.handleChangeDrawType}
                    className='ml-4'
                  />
                  <label htmlFor='drawType-manual' className='ml-2'>
                    Manual
                  </label>
                </div>
              </ContentContainer>
              <ContentContainer>
                <h2 className='mini-label font-medium'>Adjust your segment</h2>
                <div id='map' className='map'></div>
              </ContentContainer>
              <ContentContainer>
                <h2 className='mini-label font-medium'>
                  Additional information (optional)
                </h2>
                <textarea
                  name='description'
                  id='description'
                  className='w-full'
                  placeholder='An epic description.'
                  required={false}
                  onChange={this.handleChange}
                  value={description}
                ></textarea>
              </ContentContainer>
              <ContentContainer>
                <h2 className='mini-label font-medium'>Elevation</h2>
                <canvas className='w-full elevation-chart'></canvas>
              </ContentContainer>
            </div>
          </div>
          <button type='submit' className='button dark mb-4'>
            Confirm and create segment
          </button>

          {error && <p className='text-text-grey-600 max-w-[35rem]'>{error}</p>}
        </form>
      </section>
    );
  }
}
export default AdminCreateSegment;
